.homeAbout{
    text-align: left;
    font-size: 30px;
    color: black;
    margin-left: 5%;
    margin-top: 7%;
}
.loginnow{
    color: rgb(35, 132, 196);
}
.p{
    margin-top: 5%;
}
strong{
    font-size: 25px;
}