
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.6); }
.map-container-2{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
  }
  .map-container-2 iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }

  .fixed-width-td {
    width: 150px; /* set your desired width here */
    word-wrap: break-word; /* to handle long content and prevent overflow */
  }