.title{
  text-align: center;
}

.information{
  margin: 0 10% 0 15% ;
  
  
}
.section-contact{
  margin-top: 50px;
  
  
}