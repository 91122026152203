.hero {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  z-index: -1;
  height: 120vh;
  background-image: url("./8309.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.majed {
  position: relative;
  margin-top: 5%;
}
.imgg {
  background-image: url("./8309.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 120vh;
}

.majed1 {
  margin-top: 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


img {
  width: 800px;
  height: 600px;
}
.buttonn{
  background-color: #8FC8EB;
  border-radius: 100px;
  color: #19335A;
  border: none;
  width: 200px;
  height: 50px;
}



@media (max-width: 1050px) {
  .majed1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
}

@media (max-width: 768px) {
  .majed1 {
    margin-top: 25%;
  }
  img {
    width: 80%;
    height: 80%;
  }
  div {
    text-align: center;
  }
}
