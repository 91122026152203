.team .items {
    transition: 0.5s;
    border-radius: 0 0 10px 10px ;
    background-color: rgb(225, 231, 231);
  }
.team .img {
    position: relative;
  }
.team{
    text-align: center;
    margin-top: 100px;
    margin-left: 10px;
    margin-right: 10px; 
  }
.team img {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }
  .team .details {
    padding: 30px;
    text-align: center;
    border-radius: 0 0 10px 10px ;
    background-color: rgb(225, 231, 231);
  }
  .team .details h2 {
    font-weight: 500;
    font-size: 20px;
    transition: 0.5s;
    background-color: rgb(225, 231, 231);
  }
  .team .details p {
    font-size: 15px;
    color:#19335A;
    margin-top: 10px;
    transition: 0.5s;
    background-color: rgb(225, 231, 231);

  }
  .team .items:hover {
    color: #4675c0;
    cursor: pointer;
    

  }
  .team .items:hover p {
    color: #54a8f6;
    
  }
  .grid {
    display:grid;
    margin-top: 5%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  
@media (max-width:1200px) {
  .grid {
    display:grid;
    margin-top:2%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
 .team{
  margin-top:5%;
  
 }

}
@media (max-width:768px){
  .grid {
    display:grid;
    margin-top: 5%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  .team{
    text-align: center;
    margin-top: 100px;
    
  }



}
